import * as React from "react"
import service_01 from "../images/service_01.jpg"
import service_02 from "../images/service_02.jpg"
import service_03 from "../images/service_03.jpg"
import service_04 from "../images/service_04.jpg"

// Data
const serviceList = {
  "skin": {
    "image": service_01,
    "title": "Skin",
    "text": "Special skin touch technique with silky-smooth silicone material that go beyond your subliminal feeling.",
  },
  "breasts": {
    "image": service_02,
    "title": "Breasts",
    "text": "Liberate your breasts and nipples, and make them self aware to become the nurturing center of your soul.",
  },
  "ass": {
    "image": service_03,
    "title": "Ass",
    "text": "Applying oil elegantly, merging the whole ass with oil thoughly and massaging it sensitively.",
  },
  "vagina": {
    "image": service_04,
    "title": "Vagina",
    "text": "Vibrate and pleasure your G-spot to help you reach your climax only exclusive to female body.",
  },
}

// Markup
const ServicePartial = () => {
  return (
    <div>
      {/* <!-- SERVICES SECTION : begin --> */}
      <section id="services">

          {/* <!-- SECTION HEADER : begin --> */}
          <header>
              <div class="container">
                  <h2>My Services</h2>
                  <p class="subtitle">I offer you what you really need to reclaim how awesome it is to be a woman.</p>
              </div>
          </header>
          {/* <!-- SECTION HEADER : end --> */}

          {/* <!-- SERVICE LIST : begin --> */}
          <div class="service-list">
              <div class="container">
                  <div class="row service-list-inner">

                    {Object.entries(serviceList).map(([name, serviceListItem], index) => (
                        <div class="service-list-item">
                            <div class="card">
                                <img class="card-img-top" src={serviceListItem.image} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">{serviceListItem.title}</h5>
                                </div>
                                <div class="card-text">
                                    <p>{serviceListItem.text}</p>
                                </div>
                            </div>
                        </div>
                      ))
                    }

                  </div>
              </div>
          </div>
          {/* <!-- SERVICE LIST : end --> */}

      </section>
      {/* <!-- SERVICES SECTION : end --> */}
    </div>
  )
}

export default ServicePartial
