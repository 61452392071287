import * as React from "react"

// Markup
const FooterInclude = () => {
  return (
    <div>

      {/* FOOTER : begin */}
      <footer id="footer">
          <div class="container">

              {/* FOOTER BOTTOM : begin */}
              <div class="footer-bottom">
                  <div class="row">
                      <div class="col-md-1 col-md-push-11">

                          &copy; {(new Date()).getFullYear()}

                      </div>
                  </div>
              </div>
              {/* FOOTER BOTTOM : end */}

          </div>
      </footer>
      {/* FOOTER : end */}

    </div>
  )
}

export default FooterInclude
