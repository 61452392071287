import * as React from "react"

// Markup
const LocationPartial = () => {
  return (
    <div>
      {/* BOTTOM PANEL : begin */}
      <div id="bottom-panel">
          <div class="bottom-panel-inner">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">

                          {/* BOTTOM TEXT : begin */}
                          <div class="bottom-text various-content">
                              <h3>Location</h3>
                              <p>SpaMakati.com is only for woman who is living in <strong>Makati city, Metro Manila, Philippines.</strong></p>
                          </div>
                          {/* BOTTOM TEXT : end */}

                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* BOTTOM PANEL : end */}
    </div>
  )
}

export default LocationPartial
