// import * as React from "react"
import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import HeaderInclude from "../includes/header.include"
import FooterInclude from "../includes/footer.include"
import BannerPartial from "../partials/banner.partial"
import ServicePartial from "../partials/service.partial"
import TestimonialsPartial from "../partials/testimonials.partial"
import BrandsPartial from "../partials/brands.partial"
import LocationPartial from "../partials/location.partial"


import "../css/font.css"
import "../css/default.css"
import "../css/style.css"
import "../css/custom.css"

import "../sass/app.scss"

import slickEffect from "../effects/slick.effect"

// Markup
const IndexPage = () => {

  useEffect(() => {
    slickEffect()
  })

  return (
    <div>
      <Helmet>
        <title>SpaMakati | spamakati.com</title>
        <meta name="keywords" content="Spa, Massage, Makati, Manila, Metro Manila, Philippines" />
        <meta name="description" content="The exciting massage journey for women living in Makati city, Metro Manila, Philippines." />
        <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "bba8bf47cfa64e90a31d10d47a28f25a"}'></script>
      </Helmet>

      <HeaderInclude />

      {/*WRAPPER : begin*/}
      <div id="wrapper">

        <BannerPartial />

        {/* CORE : begin */}
        <div id="core">

            {/* PAGE CONTENT : begin */}
            <div id="page-content">

                {/* VARIOUS CONTENT : begin */}
                <div class="various-content">

                  <ServicePartial />

                  <TestimonialsPartial />

                  <BrandsPartial />

                </div>
                {/* VARIOUS CONTENT : end */}

            </div>
            {/* PAGE CONTENT : end */}

        </div>
        {/* CORE : end */}

        <LocationPartial />

        <FooterInclude />

      </div>
      {/* WRAPPER : end */}
    </div>
  )
}


export default IndexPage
