import * as React from "react"
import logo from "../images/logo.png"
import header_bg from "../images/header_bg.jpg"

// Markup
const HeaderInclude = () => {
  return (
    <div>
      {/* HEADER : begin */}
      <header id="header" class="m-animated">
          <div class="header-bg" style={{backgroundImage: `url(${header_bg})`}}>
              <div class="header-inner" style={{backgroundImage: "linear-gradient(to bottom, rgba(255, 0, 124, 0.6), #099db3)"}}>
      
                  {/* HEADER BRANDING : begin */}
                  <div class="header-branding">
                      <a href="/"><img src={logo} alt="SpaMakati" width="292" /></a>
                  </div>
                  {/* HEADER BRANDING : end */}
      
                  {/* HEADER NAVIGATION : begin */}
                  <div class="header-navigation">
      
                  </div>
                  {/* HEADER NAVIGATION : end */}
      
                  {/* HEADER PANEL : begin */}
                  <div class="header-panel">
      
                      {/* HEADER RESERVATION : begin */}
                      <div class="header-reservation">
                          <a href="mailto:spamakati.com@gmail.com" class="c-button">Make a Reservation</a>
                      </div>
                      {/* HEADER RESERVATION : end */}
      
                      {/* HEADER CONTACT : begin */}
                      <div class="header-contact">
                          <ul>

                              {/* HOURS : begin */}
                              <li>
                                  <div class="item-inner">
                                      <i class="ico fa fa-clock-o"></i>
                                      <dl>
                                          <dt>Monday to Sunday: </dt>
                                          <dd>24 Hours Every Day</dd>
                                      </dl>
                                  </div>
                              </li>
                              {/* HOURS : end */}
      
                          </ul>
                      </div>
                      {/* HEADER CONTACT : end */}
      
                      {/* HEADER SOCIAL : begin */}
                      <div class="header-social">
                          <ul>
                              {/* @foreach(config('views.social')['social_list'] as $item)
                                  <li><a href="{{ $item['href'] }}" title="{{ $item['title'] }}"><i class="{{ $item['class'] }}"></i></a></li>
                              @endforeach */}
                          </ul>
                      </div>
                      {/* HEADER SOCIAL : end */}
      
                  </div>
                  {/* HEADER PANEL : end */}
      
              </div>
          </div>
      </header>
      {/* HEADER : end */}
    </div>
  )
}

export default HeaderInclude
