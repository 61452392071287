import * as React from "react"

// Markup
const BannerPartial = () => {
  return (
    <div>
      {/* <!-- MAIN SLIDER : begin --> */}
      <div id="main-slider">
        <video id="video" width="100%" height="720" autoplay controls preload="auto" controlsList="nodownload">
          <source src="https://drive.google.com/uc?export=download&id=1Rk6SDmMOOyTWTRznlLst269VK7lXfhrR" type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <!-- MAIN SLIDER : end --> */}
    </div>
  )
}

export default BannerPartial
