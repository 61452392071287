import * as React from "react"
import friend_01 from "../images/friend_01.png"
import friend_02 from "../images/friend_02.png"
import friend_03 from "../images/friend_03.png"
import friend_04 from "../images/friend_04.png"

// Data
const testimonialList = [
  {
      "occupation": "Startup CEO",
      "avatar": friend_01,
      "testimonial": "Thank you. It was a wonderful and exciting experience.",
  },
  {
      "occupation": "Blogger",
      "avatar": friend_02,
      "testimonial": "Thank you. I have been enjoying his special touching skills very much.",
  },
  {
      "occupation": "Diplomat",
      "avatar": friend_03,
      "testimonial": "Thank you. I felt the great enjoyment.",
  },
  {
      "occupation": "Public Figure",
      "avatar": friend_04,
      "testimonial": "Thank you. It reminds me how great to be a woman.",
  },
]

// Markup
const TestimonialsPartial = () => {
  return (
    <div>
      {/* TESTIMONIALS SECTION : begin */}
      <section>

          {/* SECTION HEADER : begin */}
          <header>
              <div class="container">
                  <h2>My Friends</h2>
                  <p class="subtitle">Read why they love me so much.</p>
              </div>
          </header>
          {/* SECTION HEADER : end */}

          {/* TESTIMONIAL LIST : begin */}
          <div class="testimonial-list">
              <div class="container">
                  <div class="row testimonial-list-inner" style={{"opacity": "1", "display": "block"}}>

                    {testimonialList.map(testimonialListItem => (
                      <div class="item active" style={{"width": "1093px"}}>
                              <div class="testimonial-list-item">

                                  {/* TESTIMONIAL : begin */}
                                  <div class="testimonial">
                                      <div class="testimonial-inner">
                                          <p class="testimonial-portrait">
                                              <span><img src={testimonialListItem.avatar} alt={testimonialListItem.occupation} /></span>
                                          </p>
                                          <blockquote>
                                              <p>{testimonialListItem.testimonial}</p>
                                              <footer><strong>{testimonialListItem.occupation}</strong></footer>
                                          </blockquote>
                                      </div>
                                  </div>
                                  {/* TESTIMONIAL : end */}

                              </div>
                          </div>
                    ))}

                  </div>
              </div>
          </div>
          {/* TESTIMONIAL LIST : end */}

      </section>
      {/* TESTIMONIALS SECTION : end */}
    </div>
  )
}

export default TestimonialsPartial
