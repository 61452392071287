import * as React from "react"
import brand_01 from "../images/brand_01.png"
import brand_02 from "../images/brand_02.png"
import brand_03 from "../images/brand_03.png"
import brand_04 from "../images/brand_04.png"

// Data
const brandList = [
  {
    "image": brand_01,
    "name": "Human Nature",
  },
  {
    "image": brand_02,
    "name": "Olay",
  },
  {
    "image": brand_03,
    "name": "Nivea",
  },
  {
    "image": brand_04,
    "name": "Unilever",
  }
]

// Markup
const BrandsPartial = () => {
  return (
    <div>
      {/* BRANDS SECTION : begin */}
      <section id="brands">

          {/* SECTION HEADER : begin */}
          <header>
              <div class="container">
                  <h2>My Favourite brands</h2>
                  <p class="subtitle">I use the high quality original products.</p>
              </div>
          </header>
          {/* SECTION HEADER : end */}

          <div class="brand-list">
              <div class="container">
                  <div class="row brand-list-inner">

                    {brandList.map(brandListItem => (
                      <div class="brand-list-item" style={{"width": "200px"}}>
                        <p class="textalign-center">
                            {/* BRAND LOGO : begin */}
                            <img src={brandListItem.image} alt={brandListItem.name} />
                            {/* BRAND LOGO : end */}
                        </p>
                      </div>
                    ))}

                  </div>
              </div>
          </div>

      </section>
      {/* BRANDS SECTION : end */}
    </div>
  )
}

export default BrandsPartial
